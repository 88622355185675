import { AlgoliaSuggestionsRequestParams, PaymentFilterType } from '@/api/algolia';
import Autocomplete from '@/app/_components/autocomplete';
import { AutocompleteItemOption } from '@/app/_components/autocomplete/types';
import { ELTextField } from '@davincihealthcare/elty-design-system-react';
import { DEFAULT_AUTOCOMPLETE_HEALTCARE_SERVICES } from '@/costants';
import { useInclinicStore, useTantoSvagoStore } from '@/store';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useEffect, useMemo, useState } from 'react';
import { useFocus } from '@/app/_hooks/useFocus';

const fetchAlgoliaSuggestions = async (search: AlgoliaSuggestionsRequestParams, setSuggestionsCb: Function) => {
  if (!search.text) {
    return;
  }
  if (search.text.length < 3) {
    setSuggestionsCb([]);
    return;
  }
  import('@/api/algolia').then(async algolia => {
    setSuggestionsCb(await algolia?.getAlgoliaSearchSuggestions(search));
  });
};

type HealthcareServiceProps = React.ComponentPropsWithoutRef<'input'> & {
  setValue: (value: string) => void;
  inline?: boolean;
};

const HealthcareService = ({ setValue, inline, value, id }: HealthcareServiceProps) => {
  const { searchForm } = useInclinicStore(state => state);
  const [suggestions, setSuggestions] = useState<AutocompleteItemOption[]>([]);
  const tantoSvagoStore = useTantoSvagoStore();

  const [inputRef, setFocus] = useFocus<HTMLInputElement>();
  const [shouldOpenMenu, setShouldOpenMenu] = useState(false);

  const clearInput = () => {
    setValue('');
    setFocus();
    setShouldOpenMenu(true);
  };

  useEffect(() => {
    if (searchForm.healthcareService !== value) {
      const search: AlgoliaSuggestionsRequestParams = {
        text: String(value),
        filterObj: {
          paymentType: tantoSvagoStore.apiHeaders.Authorization ? PaymentFilterType.online : undefined,
        },
      };

      const debounceTimeoutId = setTimeout(async () => {
        await fetchAlgoliaSuggestions(search, setSuggestions);
      }, 300);
      return () => {
        clearTimeout(debounceTimeoutId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, searchForm.healthcareService]);

  const options = useMemo(() => (String(value) ? suggestions : DEFAULT_AUTOCOMPLETE_HEALTCARE_SERVICES), [value, suggestions]);

  return (
    <Autocomplete
      shouldOpenMenu={shouldOpenMenu}
      setShouldOpenMenu={setShouldOpenMenu}
      options={options}
      setInputValueRef={setValue}
      inputValueRef={String(value)}
      variant={inline ? 'header' : 'page'}
      id={id || `autocomplete-${Date.now()}`}
    >
      {inline ? (
        <div className="relative inline-flex h-full w-full items-center text-xs text-slate-500">
          <input
            value={value}
            ref={inputRef}
            onChange={e => setValue(e.target.value)}
            placeholder="Ricerca prestazione/clinica"
            className="font-mendium flex h-full w-full items-center truncate rounded-l-6xl bg-gray-100 p-6 py-4 text-sm text-primary placeholder:text-[#627282] md:font-semibold"
            data-cy="input-searchbar"
          />
          {value && (
            // TODO to rework in button
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <span
              className="absolute right-2 hidden h-5 w-5 cursor-pointer text-primary peer-focus:text-primary-active md:block"
              onClick={clearInput}
              data-testid="clear-icon"
            >
              <XMarkIcon />
            </span>
          )}
        </div>
      ) : (
        <div>
          <ELTextField
            name="service"
            innerRef={inputRef}
            aria-label="service"
            placeholder="Ricerca prestazione/clinica"
            value={value}
            onChange={e => setValue(e.target.value)}
            leadingIcon={<MagnifyingGlassIcon />}
            data-cy="input-searchbar"
            onClear={value ? clearInput : undefined}
          />
        </div>
      )}
    </Autocomplete>
  );
};

export default HealthcareService;
