import { useRef } from 'react';

export const useFocus = <T extends HTMLElement = HTMLElement>() => {
  const inputRef = useRef<T>(null);
  const setFocus = () => {
    inputRef?.current?.focus?.();
  };

  return [inputRef, setFocus] as const;
};
