import clsx from 'clsx/lite';
import { AutocompleteItemOption } from './types';
import { UseComboboxReturnValue } from 'downshift';
import { AUTOCOMPLETE } from '@/costants/enum';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import { MapPinIcon } from '@heroicons/react/24/solid';
import { DAVINCI_CDN } from '@/costants/defaultValues';

type AutcompleteItemProps = {
  combobox: UseComboboxReturnValue<string>;
  item: AutocompleteItemOption;
  index: number;
  isHighlighted: boolean;
};

const AutocompleteItem = ({ combobox, item, index, isHighlighted }: AutcompleteItemProps) => {
  const renderChildren = (item: AutocompleteItemOption, isHighlighted: boolean) => {
    switch (item.type) {
      case AUTOCOMPLETE.AMBULATORY: {
        return (
          <div
            className={clsx(
              'inline-flex w-full items-center gap-x-2 rounded-md p-2 text-primary',
              isHighlighted && combobox.selectedItem !== item.value && 'bg-neutral-hover text-primary-hover',
              combobox.selectedItem === item.value && 'bg-brandBlue-200',
            )}
          >
            <div className="inline-block">
              <BuildingOfficeIcon className="h-5 w-5" />
            </div>
            <div className="grid grid-cols-1 lg:w-full lg:grid-cols-[1fr_auto] lg:place-content-between lg:items-center lg:gap-x-2">
              <span className="text-base font-medium">{item.value}</span>
              <span className="whitespace-nowrap text-xs font-normal capitalize text-neutral-lighter lg:text-right">clinica privata</span>
            </div>
          </div>
        );
      }
      case AUTOCOMPLETE.SPECIALITY: {
        return (
          <div
            className={clsx(
              'inline-flex w-full items-center gap-x-2 rounded-md p-2 text-primary',
              isHighlighted && combobox.selectedItem !== item.value && 'bg-neutral-hover text-primary-hover',
              combobox.selectedItem === item.value && 'bg-brandBlue-200',
            )}
          >
            <Image
              src={`${DAVINCI_CDN}/elty/stethoscope.svg`}
              alt="Visita specialistica"
              className="inline-block h-5 w-5"
              height={20}
              width={20}
            />
            <div className="grid grid-cols-1 lg:w-full lg:grid-cols-[1fr_auto] lg:place-content-between lg:items-center lg:gap-x-2">
              <span className="text-base font-medium">{item.value}</span>
              <span className="whitespace-nowrap text-xs font-normal capitalize text-neutral-lighter lg:text-right">
                visita specialistica
              </span>
            </div>
          </div>
        );
      }
      case AUTOCOMPLETE.SPECIALIZATION: {
        return (
          <div
            className={clsx(
              'inline-flex w-full items-center gap-x-2 rounded-md p-2 text-primary',
              isHighlighted && combobox.selectedItem !== item.value && 'bg-neutral-hover text-primary-hover',
              combobox.selectedItem === item.value && 'bg-brandBlue-200',
            )}
          >
            <Image
              src={`${DAVINCI_CDN}/elty/stethoscope.svg`}
              alt="Specializzazione"
              className="inline-block h-5 w-5"
              height={20}
              width={20}
            />
            <div className="grid grid-cols-1 lg:w-full lg:grid-cols-[1fr_auto] lg:place-content-between lg:items-center lg:gap-x-2">
              <span className="text-base font-medium">{item.value}</span>
              <span className="whitespace-nowrap text-xs font-normal capitalize text-neutral-lighter lg:text-right">specializzazione</span>
            </div>
          </div>
        );
      }
      case AUTOCOMPLETE.LOCATION: {
        return (
          <div
            className={clsx(
              'flex w-full items-center gap-x-2 rounded-md py-2 pl-2 pr-8 text-primary',
              isHighlighted && combobox.selectedItem !== item.value && 'bg-neutral-hover text-primary-hover',
              combobox.selectedItem === item.value && 'bg-brandBlue-200',
            )}
          >
            <div className="inline-block">
              <MapPinIcon className="h-5 w-5" />
            </div>
            <div className="flex w-full flex-col lg:flex-row lg:items-center lg:justify-between">
              <span className="w-auto truncate text-base font-medium">{item.value}</span>
            </div>
          </div>
        );
      }
      case AUTOCOMPLETE.DISTRICTS_CITIES: {
        return (
          <div
            className={clsx(
              'inline-flex w-full items-center justify-between rounded-md p-2 text-primary',
              combobox.highlightedIndex === index && combobox.selectedItem !== item.value && 'bg-neutral-hover text-primary-hover',
              combobox.selectedItem === item.value && 'bg-brandBlue-200',
            )}
          >
            <span className="truncate">{item.value}</span>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <li className="m-1 flex items-center">
      <div
        className={clsx('flex w-full cursor-pointer items-center justify-between px-1.5')}
        aria-label={`${item.value}-${index}`}
        data-cy={`autocomplete item ${item.value}`}
        {...combobox.getItemProps({ item: item.value, index })}
      >
        {renderChildren(item, isHighlighted)}
      </div>
    </li>
  );
};

export default AutocompleteItem;
